import React, { useState } from "react";
import { Container, Card } from "react-bootstrap";
import Slider from "react-slick";
import "./ParticipatingHotelListingSection.scss";
import hotelJalandhar from "../../assets/hotelJalandhar.webp";
import hotelDelhi from "../../assets/hotelDelhi.webp";
import hotelVaranasi from "../../assets/radisson-varanasi-hotel.webp";
import hotelRanchi from "../../assets/hotelRanchi.webp";
import { useNavigate } from "react-router-dom";
import sliderArrow from "../../assets/slider_arrow.png";

const NextArrow = (props) => {
    const { className, onClick, last } = props;
    return (
        <img
            className={`${last ? "hide-arrow" : "show-arrow"} ${className}`}
            onClick={onClick}
            src={sliderArrow}
            alt=">"
        />
    );
};

const PrevArrow = (props) => {
    const { className, onClick, first } = props;
    return (
        <img
            className={`${first ? "hide-arrow" : "show-arrow"} ${className}`}
            style={{ rotate: "180deg" }}
            onClick={onClick}
            src={sliderArrow}
            alt="<"
        />
    );
};

const ParticipatingHotel = ({ data: { title, src, alt, href } }) => {
    const navigate = useNavigate();
    return (
        <Card
            className="border-0 participating-hotel-card cp"
            onClick={() => {
                navigate(href);
            }}
        >
            <Card.Img variant="top" className="" alt={alt} src={src} />
            <Card.Body className="border rounded-bottom text-center">
                <Card.Title className="participating-hotel-card-heading">
                    {title}
                </Card.Title>
            </Card.Body>
        </Card>
    );
};

const ParticipatingHotelListingSection = () => {
    const participatingHotelData = [
        {
            title: "Radisson Blu Plaza Delhi Airport",
            src: hotelDelhi,
            alt: "Radisson Blu Plaza Delhi Airport facade shot",
            href: "/radisson-delhi",
        },
        {
            title: "Radisson Hotel Jalandhar",
            src: hotelJalandhar,
            alt: "Radisson Hotel Jalandhar entrance",
            href: "/radisson-jalandhar",
        },

        {
            title: "Radisson Blu Hotel Ranchi",
            src: hotelRanchi,
            alt: "Radisson Blu Hotel Ranchi",
            href: "/radisson-ranchi",
        },
        
        {
            title: "Radisson Hotel Varanasi",
            src: hotelVaranasi,
            alt: "Radisson Hotel Varanasi entrance",
            href: "/radisson-varanasi",
        },
        
        
    ];
    const [sliderPosition, setSliderPosition] = useState(0);
    const sliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        draggable: false,
        arrows: participatingHotelData.length > 3,
        nextArrow: (
            <NextArrow
                last={sliderPosition === participatingHotelData.length - 3}
            />
        ),
        prevArrow: <PrevArrow first={sliderPosition === 0} />,
        afterChange: (e) => {
            setSliderPosition(e);
        },
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    className: `center ${
                        sliderPosition === 0 ? "first-element-padding" : ""
                    }`,
                    arrows: false,
                    centerMode: true,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <Container className="homepage-container">
            <section className="participating-hotel-section">
                <div className="text-center px-3">
                    <h2 className="participating-hotel-heading">
                        Participating Hotels
                    </h2>
                </div>
                <div className="participating-hotel-slider-container">
                    <Slider {...sliderSettings}>
                        {participatingHotelData.map((data, i) => (
                            <ParticipatingHotel key={i} data={data} />
                        ))}
                    </Slider>
                </div>
            </section>
        </Container>
    );
};

export default ParticipatingHotelListingSection;
