import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import HotelInfoSection from "../components/hotelInfoSection/HotelInfoSection";
import OfferAndEventSection from "../components/offerAndEventSection/OfferAndEventSection";
import Footer from "../components/footer/Footer";
import hotelImage from "../assets/radisson-ranchi-side-image.webp";
import {
    ranchiAnnualBenefitData,
    ranchiOneTimeBenefitData,
} from "../utils/tableData";
import MemberBenefitSection from "../components/memberBenefitSection/MemberBenefitSection";
import homepageBanner from "../assets/Radisson-ranchi-Banner-image.webp";
import homepageBannerMobile from "../assets/RadissonRanchiBannerMobile.webp";
import { jalandharOfferCarousalData } from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";

const RanchiHomePage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        //TO RESET STEP ONE
        if (sessionStorage.getItem("userData")) {
            sessionStorage.removeItem("step");
            sessionStorage.removeItem("d_plan");
            sessionStorage.removeItem("otp");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("userGstData");
            sessionStorage.removeItem("flag");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("m_num");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("l_id");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("z_flag");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("gate_data");
            sessionStorage.removeItem("lnk_flg");
        }
    }, []);
    const ranchiPropertyTableData = [
        {
            clone: true,
            tableHeading: "Annual Benefits",
            tableData: ranchiAnnualBenefitData,
            tableScrollId: "ranchi-annual-table-scroll",
            tableId: "ranchi-annual-main-table",
        },
        {
            clone: true,
            tableHeading: "One Time Use Benefits",
            tableHead: "Certificates",
            tableData: ranchiOneTimeBenefitData,
            tableScrollId: "ranchi-oneTime-table-scroll",
            tableId: "ranchi-oneTime-main-table",
        },
    ];
    useEffect(() => {
            sessionStorage.setItem("property", "ranchi");
        }, []);
    return (
        <>
             <Helmet>
                 <meta
                    name="description"
                    content="Enrol as a member of Prestige Club at Radisson Hotel Ranchi and enjoy exclusive benefits and special offers on dining, stay and more, all year round."
                />
                <title>Membership of Radisson Hotel Ranchi</title>
            </Helmet>
            <Header />
            <HeroSection
                src={homepageBanner}
                mobileSrc={homepageBannerMobile}
                showForm={true}
                alt="Prestige Club Radisson Hotel Ranchi banner"
            />
            <HotelInfoSection
                content="Radisson Blu Hotel Ranchi features 115 well-appointed rooms equipped with modern amenities. Guests can dine in the on-site restaurants, relax at the spa, or stay active in the fitness centre. Additional services like concierge assistance, valet parking, and a travel desk enhance the overall experience."
                heading="Radisson Blu Hotel Ranchi"
                imgSrc={hotelImage}
                imgAlt="Prestige Club Radisson Hotel Ranchi side image"
            />
            <MemberBenefitSection
                property="ranchi"    
                propertyTableData={ranchiPropertyTableData}
                buyFlag={true}
            />
            <OfferAndEventSection carousalData={jalandharOfferCarousalData} />
            <Footer />
        </>
    );
};

export default RanchiHomePage;
