import delectableDining from "../assets/videos/Dining.mp4";
import lavishEscape from "../assets/videos/stay-with-us.mp4";
import sereneSpa from "../assets/videos/unwind-with-us.mp4";
import delectableDiningBanner from "../assets/dining_Banner.webp";
import lavishEscapeBanner from "../assets/stay_banner.webp";
import sereneSpaBanner from "../assets/spa_banner.webp";
import royalAwadhiBanner from "../assets/Roayl-Awadhi.jpg";
import NikkeiBanner from "../assets/NikkeiBanner.webp";
import NoshesBanner from "../assets/NoshesBanner.webp";
import ValentineSweetBanner from "../assets/Valentine-SweetBanner.webp";
import WorldRomanceBanner from "../assets/WorldRomanceBanner.webp";
import toastLoveBanner from "../assets/toastLoveBanner.webp";
import EatYourHeartOutBanner from "../assets/EatYourHeartOutBanner.webp"





export const defaultOfferCarousalData = [
    {
        key: "home-delectable-dining",
        image: false,
        offerTitle: "Delectable Dining",
        offerDetail: "Enjoy 20% off on dining",
        offerAsset: delectableDining,
        overViewBanner: delectableDiningBanner,
        overViewTitle: "Delectable Dining",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
        ],
    },
    {
        key: "home-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "home-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];

export const delhiOfferCarousalData = [
    // {
    //     key: "delhi-delectable-dining",
    //     image: false,
    //     offerTitle: "Delectable Dining",
    //     offerDetail: "Enjoy 20% off on dining",
    //     offerAsset: delectableDining,
    //     overViewBanner: delectableDiningBanner,
    //     overViewTitle: "Delectable Dining",
    //     overViewDescription: [
    //         "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
    //     ],
    // },
    // {
    //     key: "delhi-royal-awadhi",
    //     image: true,
    //     offerTitle: "Aadaab Lucknow Food Festival",
    //     offerDetail: "",
    //     offerAsset: royalAwadhiBanner,
    //     overViewBanner: royalAwadhiBanner,
    //     overViewTitle: "Aadaab Lucknow Food Festival",
    //     overViewDescription: [
    //         "Experience Nawabi opulence as we bring forgotten flavours of a glorious heritage alive on your palate. Indulge in centuries old royal Awadhi delicacies like Kakori Kabab, Paneer Firdausi, Hazratganj ka Tandoori Chooza, Kacche Gosht ki Biryani and much more at Aadaab Lucknow.",
    //         "Date: 30th August - 8th September, 2024",
    //         "Outlet: The Great Kabab Factory",
    //         "For reservations, please call: +91 7093875732",
    //     ],
    // },

    {
        key: "delhi-Eat-Your-Heart-Out",
        image: true,
        offerTitle: "Eat Your Heart Out",
        offerDetail: "",
        offerAsset: EatYourHeartOutBanner,
        overViewBanner: EatYourHeartOutBanner,
        overViewTitle: "Eat Your Heart Out",
        overViewDescription: [
            "This Valentine’s Day, proclaim your love over unlimited servings of succulent kababs, tantalizing tikkas, captivating curries, aromatic biryanis, ambrosial cocktails, and so much more. Let love happen.",
            "Date: February 14 to 16, 2025",
            "Outlet: The Great Kabab Factory, Radisson Blu Plaza Hotel, Delhi Airport",
            "For reservations, please call +91 9899900993 / WhatsApp us on +91 7290001435 ",
        ],
    },

    {
        key: "delhi-Nikkei",
        image: true,
        offerTitle: "It’s Nikkei 2.0!",
        offerDetail: "",
        offerAsset: NikkeiBanner,
        overViewBanner: NikkeiBanner,
        overViewTitle: "It’s Nikkei 2.0!",
        overViewDescription: [
            "Back by popular demand! Brace yourself for the ultimate fusion of Peruvian & Japanese cuisine at Soy x Neung Roi. From zesty ceviches to mouthwatering anticuchos, every dish is an adventure your taste buds won’t forget. Mark your calendars – February just got a lot more delicious.",
            "Date: All of February",
            "Outlet: Soy x Neung Roi",
            "For reservations, please call +91 9899900993 / WhatsApp us at +91 7290001435 ",
        ],
    },

    {
        key: "delhi-Noshes-of-NYC",
        image: true,
        offerTitle: "Noshes of NYC",
        offerDetail: "",
        offerAsset: NoshesBanner,
        overViewBanner: NoshesBanner,
        overViewTitle: "Noshes of NYC",
        overViewDescription: [
            "Craving the best of NYC? From iconic hot dogs and bagels, to sizzling pretzels and pizzas, enjoy a feast of the city's ultimate street food.",
            "Date: Every Thursday",
            "Timings: 6:30 PM to 11:30 PM",
            "Outlet: NYC",
            "For reservations, please call +91 9899900993 / WhatsApp us at +91 7290001435 ",
        ],
    },

    {
        key: "delhi-valentine-Sweet",
        image: true,
        offerTitle: "Valentine's Sweet Treats ",
        offerDetail: "",
        offerAsset: ValentineSweetBanner,
        overViewBanner: ValentineSweetBanner,
        overViewTitle: "Valentine's Sweet Treats ",
        overViewDescription: [
            "Celebrate love with a berry sweet twist! Dig into Strawberry Velvet Cake, Berry Cheesecake, handcrafted chocolates, and more. Pair your dessert with Strawberry Hot Chocolate or Sparkling Iced Tea for the perfect Valentine’s Day treat.",
            "Date: February 7 to 14, 2025",
            "Outlet: R The Lounge",
            "For reservations, please call +91 9899900993 / WhatsApp us at +91 7290001435",
            
        ],
    },


    {
        key: "delhi-world-romance",
        image: true,
        offerTitle: "A World of Romance",
        offerDetail: "",
        offerAsset: WorldRomanceBanner,
        overViewBanner: WorldRomanceBanner,
        overViewTitle: "A World of Romance ",
        overViewDescription: [
            "Celebrate love with your special someone in the luxurious setting of our Couple’s Spa. Unwind, relax, and indulge in an intimate experience designed just for two. Book your Valentine’s Week escape now and let the pampering begin.",
            "Date: February 7 to 14, 2025",
            "For reservations, please call +91 72919 18871 / WhatsApp us at +91 7290001435",
            
        ],
    },

    {
        key: "delhi-toast-love",
        image: true,
        offerTitle: "A Toast to Love",
        offerDetail: "",
        offerAsset: toastLoveBanner,
        overViewBanner: toastLoveBanner,
        overViewTitle: "A Toast to Love ",
        overViewDescription: [
            "Love deserves a stage as grand as your story this Valentine’s Day. Indulge in a lavish buffet at NYC, with live food counters, personalized desserts, and complimentary welcome drinks, all accompanied by live music. At The Deck, have a candlelit dinner under starry skies and enjoy a specially curated platter. A truly romantic evening is guaranteed.",
            "Date: February 14, 2025",
            "Timings: 6:30 PM Onwards",
            "Outlet: NYC and The Deck",
            "For reservations, please call +91 9899900993 / WhatsApp us at +91 7290001435",
            
        ],
    },

    
    {
        key: "delhi-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "delhi-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];

export const jalandharOfferCarousalData = [
    {
        key: "jalandhar-delectable-dining",
        image: false,
        offerTitle: "Delectable Dining",
        offerDetail: "Enjoy 20% off on dining",
        offerAsset: delectableDining,
        overViewBanner: delectableDiningBanner,
        overViewTitle: "Delectable Dining",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
        ],
    },
    {
        key: "jalandhar-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "jalandhar-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];

export const varanasiOfferCarousalData = [
    {
        key: "varanasi-delectable-dining",
        image: false,
        offerTitle: "Delectable Dining",
        offerDetail: "Enjoy 20% off on dining",
        offerAsset: delectableDining,
        overViewBanner: delectableDiningBanner,
        overViewTitle: "Delectable Dining",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on dining.",
        ],
    },
    {
        key: "varanasi-lavish-escape",
        image: false,
        imageAltText: "",
        offerTitle: "Lavish Escape",
        offerDetail: "Enjoy 10% off on stay",
        offerAsset: lavishEscape,
        overViewBanner: lavishEscapeBanner,
        overViewTitle: "Lavish Escape",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 10% off on an indulgent stay.",
        ],
    },
    {
        key: "varanasi-serene-spa",
        image: false,
        imageAltText: "",
        offerTitle: "Serene Spa",
        offerDetail: "Enjoy 20% off on spa",
        offerAsset: sereneSpa,
        overViewBanner: sereneSpaBanner,
        overViewTitle: "Serene Spa",
        overViewDescription: [
            "As our valued guest, we are offering you a chance to experience Prestige Club at participating Radisson hotels with a special opportunity to avail 20% off on select spa services.",
        ],
    },
];
