import React from "react";
import { Row, Col } from "react-bootstrap";
import { formatNumberWithCommas } from "../../../../utils/utils";
import cross from "../../../../assets/cross.svg";

export const MembershipDetails = React.memo(
    ({
        plan,
        discountedPrice,
        setDiscountedPrice,
        promoCode,
        setPromoCode,
        validatePromoCode,
    }) => {
        const calculateTotalPrice = () => {
            const amountAfterDiscount = plan.Price - discountedPrice;
            const gstAmount = 0.18 * amountAfterDiscount;
            return `₹ ${formatNumberWithCommas(
                Math.ceil(gstAmount + amountAfterDiscount)
            )}`;
        };
        const removePromoCode = () => {
            setPromoCode({
                value: "",
                valid: true,
                applied: false,
            });
            setDiscountedPrice(0);
            sessionStorage.removeItem("d_plan");
        };

       

        return (
            <>
                <section className="membership-detail-section">
                    <Row>
                        <Col>
                            <h1>Membership Details</h1>
                        </Col>
                    </Row>
                    <Row className="row-level-margin responsive-margin">
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={7} md={12}>
                                    <div className="membership-text">City</div>
                                </Col>
                                <Col xs={5} md={12}>
                                <div className="membership-sub-text">
                                {sessionStorage.getItem("property") === "delhi"
                                    ? "Delhi NCR"
                                    : sessionStorage.getItem("property") === "varanasi"
                                    ? "Varanasi"
                                    : sessionStorage.getItem("property") === "jalandhar"
                                    ? "Jalandhar"
                                     : sessionStorage.getItem("property") === "ranchi"
                                    ? "Ranchi"
                                    : ""}
                                    
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="responsive-margin">
                            <Row>
                                <Col xs={7} md={12}>
                                    <div className="membership-text">
                                        Country
                                    </div>
                                </Col>
                                <Col xs={5} md={12}>
                                    <div className="membership-sub-text">
                                        India
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-level-margin responsive-margin">
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={7} md={12}>
                                    <div className="membership-text">
                                        Hotel Name
                                    </div>
                                </Col>
                                <Col xs={5} md={12}>
                                <div className="membership-sub-text">
                                    {sessionStorage.getItem("property") === "delhi"
                                        ? "Radisson Blu Plaza Hotel, Delhi Airport"
                                        : sessionStorage.getItem("property") === "varanasi"
                                        ? "Radisson Hotel Varanasi"
                                        : sessionStorage.getItem("property") === "jalandhar"
                                        ? "Radisson Hotel Jalandhar"
                                         : sessionStorage.getItem("property") === "ranchi"
                                        ? "Radisson Hotel Ranchi"
                                        : ""}
                                </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="responsive-margin">
                            <Row>
                                <Col xs={7} md={12}>
                                    <div className="membership-text">
                                        Membership Level
                                    </div>
                                </Col>
                                <Col xs={5} md={12}>
                                    <div className="membership-sub-text">
                                        {plan.levelName}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="row-level-margin">
                        <Col xs={7} md={6} className="position-relative">
                            <input
                                className={`rounded ${
                                    promoCode.valid ? "" : "invalid"
                                }`}
                                placeholder="Apply Promo Code"
                                value={promoCode.value}
                                disabled={promoCode.applied}
                                onChange={(e) => {
                                    setPromoCode((prev) => ({
                                        ...prev,
                                        value: e.target.value
                                            .trim()
                                            .toUpperCase(),
                                    }));
                                }}
                            />
                            {promoCode.applied && (
                                <img
                                    className="cross-btn"
                                    src={cross}
                                    alt="X"
                                    onClick={removePromoCode}
                                />
                            )}
                        </Col>
                        <Col xs={5} md={3}>
                            <button
                                className="rounded"
                                disabled={
                                    promoCode?.value.length === 0 ||
                                    promoCode.applied
                                }
                                onClick={validatePromoCode}
                            >
                                Apply
                            </button>
                        </Col>
                    </Row>
                    {promoCode.applied && (
                        <Row>
                            {promoCode.valid ? (
                                <Col className="success-test">
                                    Promo code applied
                                </Col>
                            ) : (
                                <Col className="fail-text">
                                    Promo code applied is not valid
                                </Col>
                            )}
                        </Row>
                    )}
                </section>
                <hr />
                <section className="membership-price-section">
                    <Row>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={7} md={7}>
                                    <p className="price-text">
                                        Base Membeship Fee
                                    </p>
                                </Col>
                                <Col xs={5} md={5}>
                                    <p className="price-sub-text">
                                        ₹ {plan.Price}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={7} md={7}>
                                    <p className="price-text">Promo Discount</p>
                                </Col>
                                <Col xs={5} md={5}>
                                    <p className="price-sub-text">
                                        {discountedPrice === 0
                                            ? 0
                                            : `₹ ${formatNumberWithCommas(
                                                  discountedPrice
                                              )}`}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={7} md={7}>
                                    <p className="price-text">GST</p>
                                </Col>
                                <Col xs={5} md={5}>
                                    <p className="price-sub-text">18.0%</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <Col xs={7} md={7}>
                                    <p className="price-text total-text">
                                        Total Membeship Fee
                                    </p>
                                </Col>
                                <Col xs={5} md={7}>
                                    <p className="price-sub-text total-price">
                                        {calculateTotalPrice()}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            </>
        );
    }
);
