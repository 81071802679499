export const delhiAnnualBenefitData = {
    columnData: ["Copper", "Silver", "Gold", "Platinum"],
    rowData: [
        {
            benefit: "50% off when 2 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "33% off when 3 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "25% off when 4 guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off when 5 or more guests dine",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off when 1 guest dines",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "30% off when 3 to 10 guests dine or when Member dines alone",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "20% off on beverages. Not valid on bottle rates",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and soft beverages for up to 10 guests on Monday and Tuesday at NYC or Savannah",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "30% off on food and soft beverages for up to 10 guests on Monday and Tuesday at TGKF",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and 20% off on beverages over lunch for up to 10 guests on Thursday and Sunday at Neung Roi",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "50% off on food and 20% off on beverages for up to 20 ladies every Saturday and Sunday at Neung Roi",
            copper: "",
            silver: "",
            gold: "",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Happy Hours 1 + 1 on select brands only from Monday to Thursday between 5 pm and 9 pm.",
            copper: "",
            silver: "",
            gold: "",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Happy Hours 1 + 1 on select brands, 5 pm onwards.",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },

        {
            benefit: "Pastry Shop",
            copper: "20% ",
            silver: "20% ",
            gold: "25% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Spa & Salon",
            copper: "30% ",
            silver: "30% ",
            gold: "40% ",
            platinum: "50% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Laundry",
            copper: "30% ",
            silver: "30% ",
            gold: "40% ",
            platinum: "50% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Member's Day (Spa & Salon) - Every Wednesday",
            copper: "50% ",
            silver: "50% ",
            gold: "50% ",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Business Centre",
            copper: "20% ",
            silver: "20% ",
            gold: "25% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Health Club Membership",
            copper: "15% ",
            silver: "15% ",
            gold: "20% ",
            platinum: "30% ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
    ],
};

export const delhiOneTimeBenefitData = {
    columnData: ["Copper", "Silver", "Gold", "Platinum"],
    rowData: [
        {
            benefit: "Buffet Meal for 2 guests at NYC",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "House Wine / Cake (max 1 Kg)",
            copper: "2",
            silver: "2",
            gold: "2",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Dining at NYC or Neung Roi",
            copper: "2",
            silver: "2",
            gold: "3",
            platinum: "4",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Dining at TGKF",
            copper: "1",
            silver: "1",
            gold: "2",
            platinum: "3",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Sunday Brunch at the NYC",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "30% off on Dining at TGKF",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "-",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Night stay in Superior Room inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "1 ",
            gold: "1 ",
            platinum: "- ",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Stay in Business Class Room inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit:
                "Stay in Executive Suite inclusive of Buffet Breakfast for 2 guests",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "1",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on body massage for 1 hour",
            copper: "-",
            silver: "-",
            gold: "1",
            platinum: "3",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "Body massage for 1 hour",
            copper: "-",
            silver: "-",
            gold: "-",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
        {
            benefit: "50% off on Business Centre usage",
            copper: "1",
            silver: "1",
            gold: "1",
            platinum: "2",
            copperSub: "",
            silverSub: "",
            goldSub: "",
            platinumSub: "",
        },
    ],
};

export const jalandharAnnualBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit:
                "25% off on food and beverage bill for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "20% off on alcoholic beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "25% off on non-alcoholic beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit: "20% off on the F&B bill at all participating hotels",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "Member’s Day every Tuesday with 50% off on the food bill and 20% on beverages for a maximum of 10 guests",
            silver: "",
            silverSub: "",
        },
        {
            benefit:
                "Happy Hours Monday to Friday with 1+1 on IMFL from 6 pm to 8 pm",
            silver: "",
            silverSub: "",
        },
        {
            benefit: "Pastry Shop",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Pastry Shop after 7:30 pm from Monday to Friday",
            silver: "30%",
            silverSub: "",
        },
        {
            benefit: "Stay",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Spa and Salon",
            silver: "20%",
            silverSub: "",
        },
        {
            benefit: "Health Club membership",
            silver: "15%",
            silverSub: "",
        },
    ],
};

export const jalandharOneTimeBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit: "Buffet Lunch for 2 guests",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "Cake (max 1 kg)",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "50% off on Cake (min 2 kg and max 5 kg)",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "One dessert for each main course ordered for minimum 4 and maximum 10 guests",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit:
                "25% off on the food bill over lunch at The Great Kabab Factory for a minimum of 30 guests",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit: "Night stay including Buffet Breakfast for 2 guests",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "30% off on the published room tariff over weekends from June to September",
            silver: "One",
            silverSub: "",
        },
        {
            benefit: "Upgrade to a Club Room",
            silver: "One",
            silverSub: "",
        },
        {
            benefit:
                "Swimming pool access for 2 guests for a maximum of 4 hours",
            silver: "Two",
            silverSub: "",
        },
        {
            benefit: "50% off at the Spa or Salon",
            silver: "One",
            silverSub: "",
        },
    ],
};

export const varanasiAnnualBenefitData = {
    columnData: ["Silver", "Gold"],
    rowData: [
        {
            benefit: "50% off on food bill when 2 guests dine",
            silver: "",
            gold: "",
        },
        {
            benefit: "33% off on food bill when 3 guests dine",
            silver: "",
            gold: "",
        },
        {
            benefit: "25% off on food bill when 4 guests dine",
            silver: "",
            gold: "",
        },
        {
            benefit: "20% off on food bill when 5 to 10 guests dine",
            silver: "",
            gold: "",
        },
        {
            benefit: "20% off on food bill when Member dines alone",
            silver: "",
            gold: "",
        },
        {
            benefit: "15% off on food bill when 11 to 20 guests dine",
            silver: "",
            gold: "",
        },
        {
            benefit: "30% off on alcoholic beverages for up to 20 guests",
            silver: "",
            gold: "",
        },

        {
            benefit: "20% off on non-alcoholic beverages for up to 20 guests",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "Happy Hours with 1+1 on drinks (IMFL and beer only) everyday from 12 noon to 6 pm at The Oakwood Bar",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "50% off on food, 30% off on alcoholic beverages and 20% off on non-alcoholic beverages for up to 10 guests at eastWEST on Thursday.",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "30% off on the food and beverage bill for up to 10 guests over Dinner at The Great Kabab Factory every Tuesday.",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "30% off on the food and beverage bill for up to 10 guests over Lunch at The Great Kabab Factory every Sunday.",
            silver: "",
            gold: "",
        },
        {
            benefit: "25% off on Pastry Shop",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "20% off on take‑away orders for up to 20 guests. Not valid at The Great Kabab Factory.",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "50% discount on the Published Room Tariff on any day of the week.",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "20% discount on the Best Available Rate on any day of the week.",
            silver: "",
            gold: "",
        },
        {
            benefit: "25% off on laundry services",
            silver: "",
            gold: "",
        },
        {
            benefit:
                "25% off on the food and beverage bill over lunch at The Great Kabab Factory for 50 guests or more. Valid between Monday and Saturday.",
            silver: "-",
            gold: "",
        },
        {
            benefit:
                "20% off on dining up to 10 guests at participating hotels in India",
            silver: "",
            gold: "",
        },
    ],
};

export const varanasiOneTimeBenefitData = {
    columnData: ["Silver", "Gold"],
    rowData: [
        {
            benefit: "Buffet dinner for 2 guests at eastWEST",
            silver: "1",
            gold: "1",
        },
        {
            benefit: "Cake (max. 1 kg)",
            silver: "1",
            gold: "1",
        },
        {
            benefit: "Tea/Coffee for 2 guests at eastWEST or R The Lounge",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "50% off on food, 30% off on alcoholic beverages and 20% off on non-alcoholic beverages for up to 8 guests at eastWEST",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "50% off on food, 30% off on alcoholic beverages and 20% off on non-alcoholic beverages for up to 8 guests at R The Lounge",
            silver: "1",
            gold: "1",
        },
        {
            benefit: "30% off on cake (2 kg to 5 kg)",
            silver: "2",
            gold: "2",
        },
        {
            benefit: "Complimentary dessert (max 4)",
            silver: "2",
            gold: "2",
        },
        {
            benefit: "INR 400 off buffet meal at eastWEST on weekdays",
            silver: "4",
            gold: "4",
        },
        {
            benefit: "Bottle of domestic wine",
            silver: "-",
            gold: "1",
        },
        {
            benefit:
                "Cake and venue decoration while booking the banquet for 75 guests or more",
            silver: "-",
            gold: "1",
        },
        {
            benefit:
                "Night stay in Superior Room including Buffet Breakfast for 2 guests",
            silver: "1",
            gold: "1",
        },
        {
            benefit:
                "Night stay in Superior Room including Buffet Breakfast for 2 guests valid between April and September",
            silver: "-",
            gold: "1",
        },
        {
            benefit:
                "50% off on published tariff or 20% off on Best Available Rate for 3 consecutive nights for 2 guests",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "20% off on Best Available Rate for 1 night inclusive of buffet breakfast for 2 guests at Radisson Blu Plaza Delhi Airport",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "Complimentary 2nd night when 1st is paid on the Best Available Rate in an Executive Suite at Radisson Blu Plaza Delhi Airport",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "50% off on Suite on the published tariff for 2 guests. Valid between April and September",
            silver: "-",
            gold: "1",
        },
        {
            benefit:
                "30% off on Best Available Rate at participating hotels in India",
            silver: "3",
            gold: "3",
        },
        {
            benefit:
                "15% off on package rates for direct bookings at participating hotels in India",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "25% off on body massage at R The Spa, Radisson Blu Plaza Delhi Airport ",
            silver: "2",
            gold: "2",
        },
        {
            benefit:
                "10% off on the Health Club/Gymnasium membership at R The Spa, Radisson Blu Plaza Delhi Airport",
            silver: "1",
            gold: "1",
        },
    ],
};

export const ranchiAnnualBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit: "50% Off on the Food Bill for 2 Guests",
            silver: "",
        },
        {
            benefit: "33% Off on the Food Bill for 3 Guests ",
            silver: "",
            
        },
        {
            benefit: "25% Off on the Food Bill for 4 Guests",
            silver: "",
            
        },
        {
            benefit: "20% Off on the Food Bill for 1 Guest",
            silver: "",
            
        },
        {
            benefit: "20% Off on the Food Bill for a Table of 5-10 Guests",
            silver: "",
            
        },
        {
            benefit: "20% Off on the Food Bill at Caramel",
            silver: "",
            
        },
        {
            benefit: "15% Off on the Food Bill for a Table of 11-14 Guests",
            silver: "",
            
        },

        {
            benefit: "20% Off on the Beverage Bill for a Table of up to 8 Guests",
            silver: "",
            
        },
        {
            benefit:
                "20% Off on IMFL at HEROES",
            silver: "",
            
        },
        {
            benefit:
                "Member’s Day - 50% Off on Buffet Meal for a Table of up to 10 Guests on Tuesdays and Sundays",
            silver: "",
            
        },
        {
            benefit:
                "Happy Hours (1+1 on IMFL) at Skylit",
            silver: "",
            
        },
        {
            benefit:
                "20% Off on In‑room Dining",
            silver: "",
            
        },
        {
            benefit: "30% Off on Published Tariff",
            silver: "",
            
        },
        {
            benefit:"Member’s Day ‑ 30% Off at Nilaya Spa on Tuesdays",
            silver: "",
            
        },
        {
            benefit:
                "15% Off on Health Club Membership",
            silver: "",
            
        },
       
    ],
};

export const ranchiOneTimeBenefitData = {
    columnData: ["Silver"],
    rowData: [
        {
            benefit: "Complimentary Buffet Meal ",
            silver: "1",
            
        },
        {
            benefit: "Complimentary Chocolate / Pineapple Cake ",
            silver: "1",
            
        },
        {
            benefit: "Complimentary Round of Tea / Coffee ",
            silver: "2",
            
        },
        {
            benefit:
                "Complimentary Bottle of Red Wine ",
            silver: "1",
            
        },
        {
            benefit:
                "Complimentary Dessert ",
            silver: "2",
            
        },
        {
            benefit: "50% Off on the Food Bill and 20% Off on the Beverage Bill ",
            silver: "2",
            
        },
        {
            benefit: "20% Off on the Food and Beverage Bill",
            silver: "1",
            
        },
        {
            benefit: "Complimentary Night Stay ",
            silver: "1",
            
        },
        {
            benefit: "50% Off on Published Tariff ",
            silver: "2",
            
        },
        {
            benefit:
                "20% Off on Spa",
            silver: "4",
            
        },
        {
            benefit:
                "20% Off on Spa and Salon",
            silver: "6",
            
        },
        {
            benefit:
                "20% Off on Published Banquet Rates for over 300 Guests",
            silver: "1",
            
        },
        {
            benefit:
                "15% Off on Published Banquet Rates for up to 200 Guests",
            silver: "1",
            
        },
        {
            benefit:
                "10% Off on Published Banquet Rates for up to 150 Guests",
            silver: "1",
            
        },
        
    ],
};
